import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

const burger = () => {
	const burgerEl      = document?.querySelector('[data-burger]');
	const menu          = document?.querySelector('[data-menu]');
	const targetElement = document.querySelector('body');

	burgerEl?.addEventListener('click', () => {
		burgerEl?.classList.toggle('burger--active');
		menu?.classList.toggle('active');

		if (menu?.classList.contains('active')) {
			burgerEl?.setAttribute('aria-expanded', 'true');
			burgerEl?.setAttribute('aria-label', 'Сlose menu');
			disableBodyScroll(targetElement);
		} else {
			burgerEl?.setAttribute('aria-expanded', 'false');
			burgerEl?.setAttribute('aria-label', 'Open menu');
			enableBodyScroll(targetElement);
		}
	});
};

export default burger;
