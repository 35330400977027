import GraphModal from 'graph-modal';

const modal = new GraphModal({

	isOpen : (modal) => {
		const header = document.querySelector('.header');
	},
	isClose : () => {

	},
});

export default modal;
