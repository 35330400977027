import Choices from 'choices.js';

const initSelects = () => {
	const element = document.getElementById('select-level');
	if (element) {
		const choices = new Choices(element, {
			searchChoices    : false,
			searchEnabled    : false,
			removeItemButton : true,
			classNames       : {
				containerOuter : 'choices choices--level',
			},
		});
	}

	const direction = document.getElementById('select-direction');
	if (direction) {
		const choicesDirection = new Choices(direction, {
			searchChoices    : false,
			searchEnabled    : false,
			removeItemButton : true,
			classNames       : {
				containerOuter : 'choices choices--direction',
			},
		});
	}

	const duration = document.getElementById('select-duration');
	if (duration) {
		const choicesDuration = new Choices(duration, {
			searchChoices    : false,
			searchEnabled    : false,
			removeItemButton : true,
			classNames       : {
				containerOuter : 'choices choices--duration',
			},
		});
	}
};

export default initSelects;
