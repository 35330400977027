import modal      from './components/modal';
import DropPanel  from './components/DropPanel';
import MainMenu   from './components/MainMenu';
import Accordion  from './components/accordionmini';
import burger     from './components/burger';
import initselect from './components/select';
import parallax   from './components/parallax';

// Init
function init() {
	Accordion();
	burger();
	initselect();
	modal;
	parallax();
}

(function () {
	init();
}());
