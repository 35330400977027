const AccordionMini = () => {
	const accordions = document.querySelectorAll('.accordion-mini'); // находим все аккардионы

	accordions.forEach((el) => {
		el.addEventListener('click', (e) => {
			const self    = e.currentTarget;
			const control = self.querySelector('.accordion-mini__control'); //
			const content = self.querySelector('.accordion-mini__content'); // то что будем открывать

			self.classList.toggle('open');

			if (self.classList.contains('open')) {
				control.setAttribute('aria-expanded', true);
				content.setAttribute('aria-hidden', false);
				content.style.maxHeight = `${content.scrollHeight}px`;
			} else {
				control.setAttribute('aria-expanded', false);
				content.setAttribute('aria-hidden', true);
				content.style.maxHeight = null;
			}
		});
	});
};
export default AccordionMini;
