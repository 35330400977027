import Parallax from 'parallax-js';

const parallax = () => {
	const scene = document.getElementById('scene');
	let parallaxInstance;

	if (scene) {
		parallaxInstance = new Parallax(scene, {
			relativeInput     : true,
			clipRelativeInput : true,
			hoverOnly         : true,
		});
	}

	const screen = window.matchMedia('(max-width: 1080px)');
	if (screen.matches) {
		parallaxInstance.destroy();
	}
};

export default parallax;
